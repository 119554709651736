'use strict';
import "./import/modules";
import "./parts/auto";
// import initMask from './parts/mask';
import sendForm from './parts/sendForm';
// import initFilter from './parts/filter';

$(() => {
   sendForm();
});
