$(function() {

    const phoneMaskEls = document.querySelectorAll('.js-phone');
    
    phoneMaskEls.forEach(function(phoneMaskEl) {
        let phoneMask = IMask(
            phoneMaskEl, {
                mask: '+7 (000) 000-00-00'
            }
        );
    });

})