import isFormValidate from './initValidate';
import $ from 'jquery';

/**
 * init
 */
export default () => {
    $('.js-ajax-form').on('submit', e => sendForm(e));
};

/**
 * Валидация перед отправкой формы
 *
 * @param {object} e event
 */
const sendForm = e => {
    let t = $(e.target);
    if (!isFormValidate(t)) {
        e.preventDefault();
        e.stopPropagation();
        $('.invalid-input', t).first().focus();
    } else if (t.hasClass('js-ajax-form')) {
        e.preventDefault();
        t.addClass('js-load ');

        sendFormAjax(t, function (data) {
            formSendResult(t, data);
            t.removeClass('js-load ');
        });
    }
};

/**
 * Отправляем форму ajax
 * @param {object} form jQuery объект формы
 * @param {function} callback функция обратного вызова
 */
function sendFormAjax(form, callback) {
    let url = 'index.php';
    sendAjax(url, form.serialize(), callback);
}

/**
 * Отправляем ajax запрос
 * @param {string} url ссылка
 * @param {object} data данные
 * @param {function} callback функция обратного вызова
 */
function sendAjax(url, data, callback) {
    callback = callback || function () {
    };

    $.ajax({
        url: url,
        dataType: 'json',
        type: 'POST',
        data: data,
        success: function (data) {
            callback(data);
        },
        error: function (data) {
            callback({
                'type': 'error',
                'class': 'danger',
                'text': data['responseText'],
            });
        },
    });
}

/**
 * Обработка отправки формы
 * @param {object} form jQuery объект формы
 * @param {object} data данные полученные от сервера
 */
function formSendResult(form, data) {

    form.after(
        '<form class="modal__form"><div class="selection__step6"><h2 class="selection__title">Благодарим <br> за вашу заявку!</h2><p class="selection__text">Персональный менеджер свяжется с вами <br> в ближайшее время.</p></div></form>'
    );
    form.remove();
}
