$(function() {

    Fancybox.bind('.js-open-modal', {
        on: {
            done: (fancybox, slide) => {
                // debugger;
            
          },
        },
        trapFocus: false,
        autoFocus: true,
        placeFocusBack: false,
        dragToClose: false,
      });


      var $select = $(".js-modal-select");
      $select.select2({
        //   dropdownParent: $('#request .modal__form'),
          minimumResultsForSearch: -1,
          placeholder: {
              id: '-1', // the value of the option
              text: ''
          },
      });

})