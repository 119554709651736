// /* eslint-disable no-unused-vars */
// /* eslint-disable no-undef */
import $ from 'jquery';

export default (form, error_class) => {
    let result = true;
    let rq = $('.required', form).length;
    let check = [
        'input[type="text"]',
        'input[type="login"]',
        'input[type="password"]',
        'input[type="number"]',
        'input[type="checkbox"]',
        'input[type="tel"]',
        'input[type="email"]',
        'input[type="select"]',
        'input[type="file"]',
        'textarea',
        'select',
    ];
    let parent;

    error_class = error_class || 'invalid-input';

    $('.required, input, textarea, select').removeClass(error_class);

    if (rq < 1) {
        return result;
    }
    for (let i = 0; i < rq; i++) {
        parent = $('.required', form).eq(i);
        // eslint-disable-next-line no-loop-func
        $(check.join(','), parent).each(function () {
            if (!isFieldValidate($(this))) {
                if ($(this).attr('type') === 'checkbox') {
                    $(this).next().addClass(error_class);
                } else {
                    $(this).addClass(error_class);
                }
                result = false;
            }
        });
    }

    if (result) {
        form.removeClass('form-invalid');
    } else {
        form.addClass('form-invalid');
    }

    return result;
};

const isValidEmail = email => {
    let pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    return pattern.test(email);
};

const isValidPhone = phone => {
    let pattern = new RegExp(/\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}/i);
    return pattern.test(phone);
};

const isValidFile = file => {
    let maxSize = $(file).data('max-size');
    let type_reg = /^image\/(jpg|png|jpeg|gif)$/;
    if (
        file.get(0).files[0].size > maxSize ||
        file.get(0).files[0].name === '' ||
        !type_reg.test(file.get(0).files[0].type)
    ) {
        return false;
    }
    return true;
};

const isFieldValidate = field => {
    let result = true;
    let val = '';

    if (field && field.attr('name')) {
        if (!field.val()) {
            field.val('');
            return false;
        }

        val = (field.val() + '').trim();

        if (field.attr('name') === 'email' && !isValidEmail(val)) {
            result = false;
        } else if (field.attr('type') === 'tel' && !isValidPhone(val)) {
            result = false;
        } else if (field.attr('type') === 'checkbox' && !field.is(':checked')) {
            result = false;
        } else if (field.attr('type') === 'file' && !isValidFile(field)) {
            result = false;
        } else if (val === null || val === '') {
            field.val('');
            result = false;
        }
    }

    return result;
};
