// initAuto();
initLoading();
let prv = 1;
function initLoading() {
    $('body').on('click', '.js-to', function () {
        var t = $(this);
        var scroll = t.data('to');
        scrollToElement($('.' + scroll).eq(0), 400, null, -80)
      });
    if (!$('.trade-in__form select[name="mark"]').val()) {
        GetData($, { 'action': 'select', 'data': { 'type': 'mark' }});
    }
 };
function initAuto() {

    $('.trade-in__form select[name="mark"]').change(function () {
        $('.trade-in__form select[name="year"]').children().remove();
        $('.trade-in__form select[name="model"]').children().remove();
        $('.trade-in__form select[name="mark"]').attr("value", $(this).val());
        GetData($, {'action': 'select', 'data': {'type': 'model', 'mark': $(this).val()}});
    });

    $('.trade-in__form select[name="model"]').change( function () {
        $('.trade-in__form select[name="year"]').children().remove();
        $('.trade-in__form select[name="model"]').attr("value", $(this).val());
        GetData($, {
            'action': 'select',
            'data': {
                'type': 'year',
                'mark':  $('.trade-in__form select[name="mark"]').attr("value"),
                'model': $(this).val()
            }
        });
    });
}

/**
     * Прокручиваем страницу к блоку
     * @param {object} element - элемент на странице
     * @param {int} speed - скорость прокрутки
     * @param {function} callback - колбек
     * @param {int} diff - отступ от верхнего края окна до элемента
     */
 function scrollToElement(element, speed, callback, diff)
 {
     scrollToPos(element.offset().top, speed, callback, diff);
}
 
/**
     * Прокручиваем страницу к позиции
     * @param {int} pos - позиция на странице
     * @param {int} speed - скорость прокрутки
     * @param {function} callback - колбек
     * @param {int} diff - отступ от верхнего края окна до элемента
     */
 function scrollToPos(pos, speed, callback, diff)
 {
     callback = callback || function ()
     {
     };
     jQuery('html, body').animate({
         scrollTop: pos + (diff || 0)
     }, speed, function ()
     {
         callback();
     });
}



function GetData($, _dt) {
    var _i, _f, _j;
    //console.log('kek');
    $.ajax({
        url: '/estimate-ajax.php',
        dataType: 'json',
        type: 'POST',
        data: _dt,
        async: true,
        success: function (data) {
            _f = true;
            for (_i in data) {
                if (_f) {
                    _f = false;
                }
                if (data[_i] !== '') {
                    var sVal = data[_i];
                    if (_dt['data']['type'] == 'mark' || _dt['data']['type'] == 'model') {
                        sVal = _i;
                    }
                    $('.trade-in__form select[name="' + _dt['data']['type'] + '"]')
                        .append('<option value="' + sVal + '">' + data[_i] + '</option>');
                }
            }
            $('.trade-in__form select[name="' + _dt['data']['type'] + '"]').removeAttr('disabled').trigger('refresh');
            initAuto();
            $('.trade-in__block').trigger('refresh');
        },
        error: function (data) {
            $('.estimate_car_modal .form_block').removeClass('loading');
            //console.log(data);
        }
    });
}

