$(function() {

    var nextyear = $('.countdown').data( 'enddate' );

    $('.countdown').countdown({
        date: nextyear, // TODO Date format: 07/27/2017 17:00:00
        offset: +2, // TODO Your Timezone Offset
        day: 'день',
        days: 'дней',
        hour: 'час',
        hours: 'часов',
        minute: 'минута',
        minutes: 'минут',
        second: 'секунда',
        seconds: 'секунд',
        hideOnComplete: true
    }, function(container) {
        console.log('Done');
    });

    // --------------------------------

    $('.js-slider-intro').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
    });

})
