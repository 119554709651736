$(function() {

  let tabs = document.querySelectorAll(".tab");

  let initTab = function (tab) {

    let clearContent = function (evt) {
      let contentEl = tab.querySelector(".js-tab-content.active");
      contentEl.classList.remove("active");

      let linkEl = tab.querySelector(".js-tab-link.active");
      linkEl.classList.remove("active");
    };

    let onClickToggle = function (evt) {
      clearContent(evt);
      let contentEl = tab.querySelector("#" + this.dataset.toggle);
      contentEl.classList.add("active");
      this.classList.add("active");
    };

    let toggles = tab.querySelectorAll(".js-tab-link");
    for (let i = 0; i < toggles.length; i++) {
      toggles[i].addEventListener("click", onClickToggle);
    }
  };

  for (let i = 0; i < tabs.length; i++) {
    initTab(tabs[i]);
  }

})
