$(function() {

    $(document).ready(function() {

        $('.js-slider').each(function ( index, element ) {

            $(element).find('.js-model-slider').on('init.slick', function (event, slickObj) {
                if (! $('.js-model-slider .slick-active').width()){
                    setTimeout(function(){
                        $('.js-model-slider').slick('refresh');
                    }, 5)
                }
            });
            
            $(element).find('.js-model-slider').slick({
                slidesToShow: 1,
                arrows: false,
                asNavFor: '.js-model-thumbnail_' + element.dataset.modelname,
                vertical: false,
                autoplay: false,
                verticalSwiping: false,
                centerMode: false,
                useTransform: false,
            });

            $(element).find('.js-model-thumbnail').slick({
                slidesToShow: 3,
                arrows: true,
                vertical: true,
                focusOnSelect: true,
                verticalSwiping: false,
                autoplay: false,
                centerMode: false,      
                asNavFor: '.js-model-slider_' + element.dataset.modelname,
                responsive: [
                    {
                        breakpoint: 1023,
                        settings: {
                            arrows: false,
                            vertical: false,
                            verticalSwiping: false,
                            variableWidth: true
                        }
                    },
                ],
            });
        });

    });

    //------------------------------------------------------- 

    const CONTENT_CLASS = 'js-scroll-status-content';
    const STATUS_TAB_CLASS = 'js-scroll-status-tab';
    const ACTIVE_TAB_CLASS = 'active';

    let contentBlocks = document.querySelectorAll(`.${CONTENT_CLASS}`);

    window.addEventListener('scroll', function(evt) {
        // состояние прокрутки
        for (let i = 0; i < contentBlocks.length; i++) {
            const scrollIndent = Number(contentBlocks[i].dataset.scrollindent || 0);

            if (contentBlocks[i].getBoundingClientRect().bottom - scrollIndent > 0) {
                let targetSelector = `.${STATUS_TAB_CLASS}[href="#${contentBlocks[i].id}"]`;

                let targetTab = document.querySelector(targetSelector);
                if (!targetTab) break;

                let activeTypeEl = document.querySelector(`.${STATUS_TAB_CLASS}.${ACTIVE_TAB_CLASS}`);
                if (activeTypeEl) activeTypeEl.classList.remove(ACTIVE_TAB_CLASS);

                targetTab.classList.add(ACTIVE_TAB_CLASS);
                break;
            }
        }

    });



    $(document).on('click', 'a[href^="#"]', function (event) {
        event.preventDefault();
    
        const $targetElement = $($.attr(this, 'href'));
        let indent = 0;

        if (window.matchMedia("(min-width: 1024px)").matches) {            
            indent = this.dataset.scrollindent || 0;
        } else {
            indent = this.dataset.scrollindentmob || 0;
        }
        
        $('html, body').animate({
            scrollTop: $targetElement.offset().top - Number(indent)
        }, 500);
    });


})